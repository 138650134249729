import React, { useEffect, useState, useCallback, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  Col,
  Card,
  Tooltip,
} from "antd";
import { doPatch, doGet } from "../../../../API/apis";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { SlRefresh } from "react-icons/sl";
import Notification from "../../../../components/Notification/Notification";
import { getWaitingRoomColumn } from "../../../../components/TableColumns/TableColumns";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { waitingRoomStatus, statusMap } from "../../../../utils/Data";
import { RefreshButton } from "../../../../components/GlobalComponents/GlobalComponents";

const { confirm } = Modal;

const WaitingRoom = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [waitingList, setWaitingList] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const { providers, getProviders, officeSetting, getOfficeSettings } =
    useStateGlobalContext();


  const handleViewAllWRP = ()=>{
    navigate('/main/waiting-room')
  }

  useEffect(() => {
    getProviders();
    getOfficeSettings();
  }, []);

  const rooms = useMemo(() => officeSetting?.exam_rooms, [officeSetting]);

  const handleCheckout = (record, itemKey) => {
    confirm({
      title: `Checkout for ${record?.patient}?`,
      icon: null,
      okText: "Check Out",
      onOk() {
        handleWaitingRoomStatus(record, itemKey);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleProviderChange = useCallback(async (value, record) => {
    setSelectedProvider(value);
    const data = {
      provider: value,
    };
    try {
      const response = await doPatch(
        `/waiting-room/update/${record.id}/`,
        data
      );
      if (response.status === 201) {
        Notification.success("Provider Changed Successfully");
        getWaitingList();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleRoomChange = useCallback(async (value, record) => {
    setSelectedRoom(value);
    const data = {
      room: value,
    };
    try {
      const response = await doPatch(
        `/waiting-room/update/${record.id}/`,
        data
      );
      if (response.status === 201) {
        Notification.success("Room Changed Successfully");
        getWaitingList();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleWaitingRoomStatus = useCallback(async (record, key) => {
  
    const status = statusMap[key];
    try {
      const response = await doPatch(
        `/waiting-room/update/${record.id}/`,
        {status}
      );
      if (response.status === 201) {
        Notification.success("Status updated Successfully");
        getWaitingList();
      }
    } catch (error) {
      Notification.error("An error occurred while updating status");
    }
  }, []);

  const getWaitingList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await doGet("/waiting-room/get/");
      if (response.status === 200) {
        const sortedTasks = response.data
          .sort((a, b) => new Date(b.checkin_time) - new Date(a.checkin_time))
          .slice(0, 5); // Keep only the last 5
  
        setWaitingList(sortedTasks);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  }, []);
  

  const columns = getWaitingRoomColumn({
    navigate,
    handleProviderChange,
    providers,
    handleRoomChange,
    handleWaitingRoomStatus,
    handleCheckout,
    rooms,
    waitingRoomStatus
  });


  useEffect(() => {
    getWaitingList();
  }, [getWaitingList]);

  return (
    <Col xl={24}>
      <Card className="mt-4 shadow-sm mb-10" >
        <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-lg">Waiting Room </h1>
        <div className="flex gap-2">
        <RefreshButton onClick={getWaitingList}/>
        <Button onClick={handleViewAllWRP}>View All</Button>
        </div>
        </div>
        <CustomTable
          rowKey={(record) => record.id}
          loading={loading}
          columns={columns}
          dataSource={waitingList}
          pagination={false}
          scroll={{
            x: 1550,
            y: 230,
          }}
        />
      </Card>
    </Col>
  );
};

export default WaitingRoom;
