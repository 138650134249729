import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown } from "antd";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import SuperbillDrawer from "./component/SuperbillDrawer";
import { useParams } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import { doDelete, doPatch } from "../../../../../API/apis";
import ViewSuperbill from "./component/ViewSuperbill";
import PrintSuperBill from "./component/PrintSuperBill";
import { NumberFormatter } from "../../../../../utils/validator";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import { InvoiceStatus } from "../../../../../components/GlobalComponents/StatusLabels";
import { superbillActions } from "../../../../../utils/Data";

const Superbill = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewRecord, setViewRecord] = useState([]);
  const [printView, setPrintView] = useState(false);
  const [showSuperbill, setShowSuperbill] = useState(false);
  const { patientBasicInfo, superbill, getSuperbill } = useStateGlobalContext();

  const column = [
    {
      key: 2,
      title: "Superbill ID",
      dataIndex: "superbill_id",
      align: "center",
    },
    {
      key: 3,
      title: "Status Date / Time",
      dataIndex: "issue_date",
      align: "center",
      render: (text) => <FormatDateTime date={text} type="datetime" />,
    },
    {
      key: 5,
      title: "Provider Name",
      dataIndex: "provider_info",
      align: "center",
      render: (text) =>
        text?.map((provider, index) => (
          <span key={index}>{provider?.provider_name}</span>
        )),
    },
    {
      key: 6,
      title: "Service",
      dataIndex: "super_data_details",
      render: (superDataDetails) =>
        superDataDetails?.map((data) => (
          <div key={data?.id}>{data?.service}</div>
        )),
    },
    {
      key: 7,
      title: "Fee",
      align: "center",
      dataIndex: "super_data_details",
      render: (superDataDetails) =>
        superDataDetails?.map((data) => (
          <div key={data?.id}>
            <NumberFormatter value={data?.fee} options={{ style: "decimal" }} />
          </div>
        )),
    },
    {
      key: 8,
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => (
        <InvoiceStatus status={status ? "Sent" : "Not Sent"} />
      ),
    },
    {
      ke: 9,
      title: "Action",
      align: "center",
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: superbillActions
                ?.filter((item) => !(item?.key === 2 && record?.status))
                ?.map((item) => ({
                  ...item,
                  onClick: () => {
                    if (item.key === 1) {
                      handleView(record);
                    } else if (item.key === 2) {
                      handleStatus(record?.id);
                    } else if (item.key === 3) {
                      handlePrint(record);
                    } else if (item.key === 4) {
                      handleDelete(record?.id);
                    }
                  },
                })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const handleView = (record) => {
    setViewRecord(record);
    setShowSuperbill(true);
  };

  const handleStatus = async (record) => {
    try {
      const response = await doPatch(
        `/superbill/update-bill/${id}/${record}/`,
        { status: true }
      );
      if (response.status === 200) {
        Notification.success("Superbill Status Changed Successfully");
        getSuperbill(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrint = (record) => {
    setViewRecord(record);
    setPrintView(true);
  };

  const closePrintView = () => {
    setViewRecord(null);
    setPrintView(false);
  };

  const closeSuperbill = () => {
    setShowSuperbill(false);
  };
  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteSuperbill(record);
      },
    });
  };

  const deleteSuperbill = async (record) => {
    try {
      const response = await doDelete(
        `/superbill/delete-bill/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Superbill Deleted Successfully");
        getSuperbill(id);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  useEffect(() => {
    const fetchSuperbill = async () => {
      setLoading(true);
      await getSuperbill(id);
      setLoading(false);
    };

    fetchSuperbill();
  }, [id]);

  const {
    first_name,
    middle_name,
    last_name,
    date_of_birth,
    patient_phone,
    sex,
    other_Gender,
    gender,
  } = patientBasicInfo || {};

  const client_name = `${first_name} ${
    middle_name && middle_name
  } ${last_name}`;
  const client_gender = sex === "other" ? other_Gender : gender;
  const clientDetails = {
    clientName: client_name,
    patient_phone,
    date_of_birth,
    client_gender,
  };

  const openSuperBillModal = () => {
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Card
      className="shadow-class"
      title="Superbill"
      extra={
        <Button type="primary" onClick={openSuperBillModal}>
          Create Superbill
        </Button>
      }
    >
      <CustomTable
        columns={column}
        dataSource={superbill}
        loading={loading}
        key="superbill"
      />

      <SuperbillDrawer
        open={open}
        onClose={onCancel}
        clientDetails={clientDetails}
      />
      <ViewSuperbill
        open={showSuperbill}
        onClose={closeSuperbill}
        record={viewRecord}
      />
      <PrintSuperBill
        open={printView}
        onClose={closePrintView}
        record={viewRecord}
        clientDetails={patientBasicInfo}
        id={id}
      />
    </Card>
  );
};

export default Superbill;
