import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Dropdown, Checkbox } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { doDelete, doGet, doPatch, doPost } from "../../../API/apis";
import Notification from "../../../components/Notification/Notification";
import showConfirmModal from "../../../components/ModalConfirm/ModalConfirm";
import ViewTemplate from "./components/ViewTemplate";
import FormatDateTime from "../../../components/FormatDate/FormatDateTime";
import { maxSize, modules } from "../../../components/GlobalComponents/GlobalComponents";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { actionItems } from "../../../utils/Data";
import LabelDisplay from '../../../components/LabelDisplay/LabelDisplay'
import { useStateGlobalContext } from "../../../contexts/GlobalContext";


const NotesTemplates = () => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [viewTemplate, setViewTemplate] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editFields, setEditFields] = useState(null);
  const [isFormReady, setIsFormReady] = useState(false);
  const [imageSize, SetImageSize] = useState(false)
  const { templates: data, getTemplates} = useStateGlobalContext();

  const column = [
    {
      key: 1,
      title: "Title",
      dataIndex: "title",
      render:(text, record)=>(
        <Button type="link" onClick={()=>handleView(record)}>
            {text}
        </Button>
      )
    },
    {
      key: 2,
      title: "Added By",
      dataIndex: "added_by",
    },
    {
      key: 3,
      title: "Added Date",
      dataIndex: "added_date",
      render: (text) => (
        <span>
          <FormatDateTime date={text} type="date" />
        </span>
      ),
    },
    {
      key: 4,
      title: "Time",
      dataIndex: "added_date",
      render: (text) => (
        <span>
          <FormatDateTime date={text} type="time" />
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "5%",
      align: "center",
      render: (_, record) => {
        const availableItems = actionItems;
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 1) {
                    handleView(record);
                  } else if (item.key === 2) {
                    handleEdit(record);
                  } else if (item.key === 3) {
                    handleDelete(record.id);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const onClick = () => {
    setVisible(true);
  };

  const handleView = (record) => {
    setViewTemplate(record);
    setView(true);
  };

  const onCancel = () => {
    setVisible(false);
    setEdit(false);
    form.resetFields();
  };

  const handleCreateTemplate = async (values) => {
    if(imageSize){
      return Notification.error("Image Size is too large.")
    }
    setIsSubmitting(true);
    const data = {
      title: values.template_title,
      template: values.template,
      is_lab: values.is_lab || false,
    };
    try {
      const response = edit
        ? await doPatch(`/templates/update/${editFields.id}/`, data)
        : await doPost("/templates/add/", data);
      if (response.status === 200) {
        edit ? Notification.success("Template Updated Successfully") :Notification.success("Template created Successfully");
        getTemplates({showAll: true});
        onCancel();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = (record) => {
    setEditFields(record);
    setEdit(true);
    setVisible(true);

    setTimeout(() => {
      form.setFieldsValue({
        template_title: record?.title,
        template: record?.template,
        is_lab: record?.is_lab,
      });
      setIsFormReady(true);
    }, 0);
  };


  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        handleDeleteConfirm(record);
      },
    });
  };

  const handleDeleteConfirm = async (id) => {
    setLoading(true)
    try {
      const response = await doDelete(`/templates/delete/${id}/`);
      if (response.status === 200) {
        Notification.success("Template Deleted Successfully");
        getTemplates({showAll: true});
      }
    } catch {
      Notification.error("Something went wrong");
    } finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true); 
      try {
        await getTemplates({showAll: true}); 
      } catch (error) {
        Notification.error("Failed to load templates");
      } finally {
        setLoading(false);
      }
    };
  
    fetchTemplates();
  }, []);
  // const maxSize = 2 * 1024 * 1024; 
  const handleChange = (content, delta, source, editor) => {
    if (source === "user") {
      const imgTags = content.match(/<img[^>]+src="data:image\/[^";]+;base64,([^">]+)"/g);
  
      if (imgTags) {
        for (const imgTag of imgTags) {
          const base64Str = imgTag.match(/base64,([^">]+)/)[1];
  
          const base64Length = base64Str.length;
          const padding = base64Str.endsWith("==") ? 2 : base64Str.endsWith("=") ? 1 : 0;
          const imageSizeInBytes = (base64Length * 3) / 4 - padding;
          if (imageSizeInBytes > maxSize) {
            SetImageSize(true)
            Notification.error("Image size exceeds 2mb. Please upload a smaller image.");
          }else{
            SetImageSize(false)
          }
        }
      }
    }
  
    form.setFieldsValue({ template: content });
  };

  document.title = "Templates | American EMR";
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <h1>Templates </h1>
        <Button type="primary" onClick={onClick}>
          Create Templates
        </Button>
      </div> 

      <CustomTable
        loading={loading}
        columns={column}
        dataSource={data}
        key="templates_list"
      />
      <Modal
        title={edit ? "Update Template" : "Create Template"}
        footer={null}
        open={visible}
        width={900}
        onCancel={onCancel}
        maskClosable={false}
        closeIcon={
          <Button
            size="small"
            className="app-close-icon"
            shape="round"
            onClick={onCancel}
          >
            Close
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={handleCreateTemplate}>
          <Form.Item
            label="Title"
            name="template_title"
            rules={[
              {
                required: true,
                message: "Title is Required",
              },
            ]}
          >
            <Input placeholder="Template Title" />
          </Form.Item>
          <Form.Item
            label="Template"
            name="template"
            rules={[
              {
                required: true,
                message: "Title is Required",
              },
            ]}
          >
            <ReactQuill
              theme="snow"
              modules={modules}
              onChange={handleChange}
              style={{ height: "300px" }}
            />
          </Form.Item>
          <div className="flex justify-between mt-14">
            <Form.Item name="is_lab"
            valuePropName="checked"
            >
            <Checkbox
            > Show in Lab Results template </Checkbox>
            </Form.Item>
            <div>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              className="ml-2"
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isSubmitting
                ? edit
                  ? "Updating..."
                  : "Saving..."
                : edit
                ? "Update"
                : "Save"}
            </Button>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        width={900}
        title={viewTemplate?.title}
        open={view}
        closeIcon={
          <Button
            size="small"
            className="app-close-icon"
            shape="round"
            onClick={() => setView(false)}
          >
            Close
          </Button>
        }
        onCancel={() => setView(false)}
        footer={null}
      >
        <LabelDisplay title="Title" description={viewTemplate?.title}/>
        <LabelDisplay title="Added By" description={viewTemplate?.added_by}/>
        <LabelDisplay title="Date Added" description={<FormatDateTime date={viewTemplate?.added_date} type="datetime" />}/>
        <LabelDisplay title="Lab" description={viewTemplate?.is_lab.toString() === "true" ? "Yes" : "No"}/>
        <LabelDisplay className="flex-col items-start" title="Template" description={<ViewTemplate template={viewTemplate?.template} />}/>
      </Modal>
    </div>
  );
};

export default NotesTemplates;
