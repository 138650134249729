import React, { useState } from "react";
import { Button, Card, Radio, Form, Input, Tag } from "antd";
import { CSVLink } from "react-csv";
import { doGet } from "../../../../../API/apis";
import { formatDate } from "../../../../../utils/DateFormat";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import Notification from "../../../../../components/Notification/Notification";
import { DetailCard } from "../../../../../components/GlobalComponents/GlobalComponents";

const { Group: RadioGroup } = Radio;

const columns = [
  {
    key: "1",
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    key: "patient_mrn",
    title: "MRN",
    dataIndex: "mrn_number",
  },
  {
    key: "2",
    title: "First Name",
    dataIndex: "first_name",
    ellipsis: true,
  },
  {
    key: "3",
    title: "Middle Name",
    dataIndex: "middle_name",
    ellipsis: true,
  },
  {
    key: "4",
    title: "Last Name",
    dataIndex: "last_name",
    ellipsis: true,
  },
  {
    key: "5",
    title: "DOB",
    dataIndex: "date_of_birth",
    ellipsis: true,
    render: (text) => <FormatDate date={text}/>,
  },
  {
    key: "6",
    title: "Gender",
    ellipsis: true,
    dataIndex: "gender",
  },
  {
    key: "7",
    title: "Marital Status",
    ellipsis: true,
    dataIndex: "marital_status",
  },
];

const transformHealthRecord = (records) => {
  if (!records || !Array.isArray(records)) {
    return [];
  }

  return records.map((record) => {
    return {
      ID: record.id,
      MRN: record.mrn_number,
      FirstName: record?.first_name,
      MiddleName: record?.middle_name,
      LastName: record?.last_name,
      DOB: formatDate(record?.date_of_birth),
      Gender: record?.gender,
      MaritalStatus: record?.marital_status || "",
      LegalGuardian:
        `${record?.guardian_first_Name} ${record?.guardian_middle_Name} ${record?.guardian_last_Name}` ||
        "",
      Mobile: record?.patient_phone || "",
      Home: record?.patient_home || "",
      Work: record?.patient_work || "",
      Email: record?.patient_email || "",
      Note: record?.address_note_line_1 || "",
    };
  });
};

const Referral = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchCategory, setSearchCategory] = useState("referal_full_name");
  const handleSearch = async () => {
    const searchParam = form.getFieldValue(searchCategory);
    setLoading(true);

    try {
      const response = await doGet(
        `/reports/referal-reports/?filter_field=${searchCategory}&q=${searchParam}`
      );
      if (response.status === 200) {
        setData(response.data);
      } else if(response.status === 400){
        Notification.error("No Record Found")
        setData([])
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DetailCard title="Referral Report">
        <h1> Search By Category </h1>
        <RadioGroup
          onChange={(e) => setSearchCategory(e.target.value)}
          value={searchCategory}
          buttonStyle="solid"
          className="mb-2 mt-2"
        >
          <Radio.Button value="referal_full_name">Referral Doctor</Radio.Button>
          <Radio.Button value="referal_organization_name">
            Referral Organization
          </Radio.Button>
          <Radio.Button value="referal_group_name">Referral Group</Radio.Button>
          <Radio.Button value="wdyhau">Referral Source</Radio.Button>
        </RadioGroup>
        <Form layout="vertical" form={form}>
          <div className="flex items-center">
            <Form.Item
              name={searchCategory}
              label={
                searchCategory === "referal_full_name"
                  ? "Referral Doctor"
                  : searchCategory === "referal_organization_name"
                  ? "Referral Organization"
                  : searchCategory === "referal_group_name"
                  ? "Referral Group"
                  : "Referral Source"
              }
            >
              <Input
                allowClear={true}
                placeholder={`Search ${
                  searchCategory === "referal_full_name"
                    ? "Referral Doctor"
                    : searchCategory === "referal_organization_name"
                    ? "Referral Organization"
                    : searchCategory === "referal_group_name"
                    ? "Referral Group"
                    : "Referral Source"
                }`}
              />
            </Form.Item>
            <Button className="ml-2 mt-2" type="primary" onClick={handleSearch}>
              Submit
            </Button>
            <Button className="ml-2 mt-2" onClick={()=> setData([])}>
              Reset
            </Button>
          </div>
        </Form>
      </DetailCard>
      <Card className="shadow-class mt-4">
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results<Tag className="ml-2 bg-green-600 text-white"> {form.getFieldValue(searchCategory)}</Tag></h1>  
          {data?.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={transformHealthRecord(data)}
                filename={"PatientHealthRecord.csv"}
                className="csv-link"
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
        {data && (
          <CustomTable
            scroll={{
              x: 1000,
            }}
            loading={loading}
            columns={columns}
            dataSource={data}
            key="referral_reports"
          />
        )}
      </Card>
    </>
  );
};

export default Referral;
