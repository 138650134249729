import React from "react";
import { Card } from "antd";
import {
  DollarOutlined,
  WalletOutlined,
  CheckCircleOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { NumberFormatter } from "../../utils/validator";

const ReportsCards = ({ rxData = [], title = "", stats = [] }) => {
  const lowerTitle = title?.toLowerCase() || "";

  const safeArray = (arr) => Array.isArray(arr) ? arr : [];

  const filteredRxData = safeArray(rxData);
  const filteredStats = safeArray(stats);

  const totalRevenue = filteredRxData
    .filter((item) => item.status?.toLowerCase() === "paid")
    .reduce(
      (sum, item) =>
        sum +
        (lowerTitle === "services"
          ? item.grand_total_amount || 0
          : lowerTitle === "product"
          ? item?.total_price || item?.grand_total || 0
          : item?.grand_total || 0),
      0
    );

  const totalItems = filteredRxData.length;
  const totalItemsPaid = filteredRxData.filter(
    (item) => item.status?.toLowerCase() === "paid"
  ).length;
  const totalItemsUnpaid = filteredRxData.filter(
    (item) => item.status?.toLowerCase() === "unpaid"
  ).length;
  const totalSent = filteredStats.filter((item) => item.status === true).length;
  const totalNotSent = filteredStats.filter((item) => item.status === false).length;

  const getData = () => {
    switch (lowerTitle) {
      case "labs":
        return [
          { title: "Total Revenue", value: <NumberFormatter value={totalRevenue}/>, icon: <DollarOutlined /> },
          { title: "Total Labs", value: totalItems, icon: <AppstoreOutlined /> },
          { title: "Total Labs Paid", value: totalItemsPaid, icon: <CheckCircleOutlined /> },
          { title: "Total Labs Unpaid", value: totalItemsUnpaid, icon: <WalletOutlined /> },
        ];
      case "superbill":
        return [
          { title: "Total Superbill Created", value: filteredStats.length },
          { title: "Total Superbill Sent", value: totalSent },
          { title: "Total Superbill Unsent", value: totalNotSent },
        ];
      case "product":
        return [
          { title: "Total Revenue", value:  <NumberFormatter value={totalRevenue}/>, icon: <DollarOutlined /> },
          { title: "Total Sale", value: totalItemsPaid, icon: <AppstoreOutlined /> },
        ];
      default:
        return [
          { title: "Total Revenue", value:  <NumberFormatter value={totalRevenue}/>, icon: <DollarOutlined /> },
          { title: "Total Services", value: totalItems, icon: <AppstoreOutlined /> },
          { title: "Total Services Paid", value: totalItemsPaid, icon: <CheckCircleOutlined /> },
          { title: "Total Services Unpaid", value: totalItemsUnpaid, icon: <WalletOutlined /> },
        ];
    }
  };

  return (
    <div
      className={`grid grid-cols-1 sm:grid-cols-2 ${
        lowerTitle === "superbill" ? "lg:grid-cols-3" : "lg:grid-cols-4"
      } gap-4 mb-4 mt-4`}
    >
      {getData().map((item, index) => (
        <Card
          key={index}
          className="shadow-md rounded-lg bg-gradient-to-r from-blue-200 via-blue-50 to-blue-100 text-neutral-900 p-1"
          hoverable
        >
          <div className="flex items-center space-x-4">
            <div>
              <h3 className="text-lg font-semibold">{item.title}</h3>
              <p className="text-2xl font-bold">
                {
                  item.value
                }
              </p>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default ReportsCards;
